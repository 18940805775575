* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.merchandising {
  min-height: 100vh;
  background-color: #e8eef2;
}
/* ::placeholder {
  opacity: 1 !important;
} */

.flex_box,
.flex_box_vertical,
.flex_box_between {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flex_box_between {
  justify-content: space-between;
}
.flex_box_vertical {
  flex-direction: column;
}

.logo {
  width: 50px;
  height: 50px;
  margin: 30px 20px;
  cursor: pointer;
}

.simbol_active {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block;
  background-color: #458ebd;
  border-radius: 50%;
}

.filter_active {
  filter: brightness(0) saturate(100%) invert(19%)
    sepia(52%) saturate(3646%) hue-rotate(195deg)
    brightness(92%) contrast(98%);
  /* filter: invert(54%) sepia(8%) saturate(2641%) hue-rotate(160deg)
    brightness(93%) contrast(96%); */
}

.filter_inactive {
  filter: invert(47%) sepia(0%) saturate(401%)
    hue-rotate(218deg) brightness(96%)
    contrast(88%);
}

.notifications_count {
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 1px 6px;
  color: #ffffff;
  font-family: "Inter";
  font-weight: bold;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  background-color: #e61912;
}
.navBreadcrumb {
  text-decoration: none;
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  line-height: 150%;
}
.drag_and_drop_area {
  border: 1px dashed #757575;
  border-radius: 4px;
}
.images_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-size: cover;
}
.images_wrapper .preview_item {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 8px;
  margin-right: 24px;
}
.preview_item .preview_image {
  max-width: 200px;
  max-height: 200px;
}
.balloon_portal {
  position: relative;
}
ymaps[class$="balloon-overlay"] ymaps ymaps {
  box-shadow: none;
}
ymaps[class$="balloon__layout"] {
  padding: 14px;
  background: #ffffff;
  border-radius: 8px;
}
ymaps[class$="balloon__content"] {
  padding: 0px;
  margin: 0px;
}
ymaps[class$="balloon__tail"],
ymaps[class$="balloon__close-button"] {
  display: none;
}
.balloon_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 19px */
  color: #000000;
  margin-bottom: 8px;
}
.balloon_text {
  font-family: "Inter";
  font-size: 13px;
  line-height: 125%;
  /* or 16px */
  color: #757575;
}
.balloon_divider {
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 18px 0px;
}
.balloon_chip_error {
  width: fit-content;
  padding: 5px 10px;
  background: #ffe8e8;
  border-radius: 90px;
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ff0000;
}
.look_shop {
  font-family: "Inter";
  font-size: 16px;
  line-height: 19px;
  color: #458ebd;
  cursor: pointer;
  margin-top: 24px;
}
.look_shop:hover {
  text-decoration: underline;
}
.goods_screenshot {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.date_range_input_wrapper {
  height: 43px;
  overflow: hidden;
}
.shelf_preview_item {
  width: 64px;
  height: 64px;
  margin-right: 9px;
  cursor: pointer;
}
.slide img {
  width: 100%;
}
.animate {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}
.carousel_nav {
  display: flex;
}
.carousel_nav img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 10px;
}
.carousel_container {
  width: 100%;
  height: calc(100vh - 244px);
  overflow: hidden;
}
.carousel_container .carousel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.carousel .slide {
  width: 100%;
  opacity: 1;
  transform: translateX(-100%);
}
.carousel .slide:nth-child(1) {
  opacity: 0.5;
}
.unsplash {
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/unsplash.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.unsplash_layer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    108.43deg,
    #009ad2 0.08%,
    #0077a2 31.1%,
    #008ec2 75.72%,
    #009ad2 106.87%
  );
  opacity: 0.8;
  position: absolute;
  top: 0px;
  left: 0px;
}

/* Removing input background color for Chrome autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.agent_photo_icon {
  width: 136px;
  height: 136px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.letter_groupe_item {
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
}
.letter_groupe_item .flex_box {
  width: fit-content;
  min-height: 56px;
  padding: 10px;
}
.controlPannel {
  display: none;
}
.letter_groupe_item:hover {
  background: #e9f0ff;
}
.letter_groupe_item:hover h6 {
  color: #034f94;
}
.letter_groupe_item:hover .controlPannel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sale_point_card {
  width: 20%;
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f2f3;
  border-radius: 4px;
  padding: 10px;
}
.fixed_buttons {
  width: 100%;
  height: 110px;
  padding-top: 14px;
  padding-bottom: 36px;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  bottom: 0px;
  gap: 16px;
}
.selected_amount {
  padding: 2px 10px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #757575;
  border-radius: 6px;
  position: absolute;
  top: 9px;
  right: 32px;
}
.tag {
  padding: 2px 6px;
  background: #eaeaea;
  border-radius: 4px;
}
.tag p,
.tag span {
  line-height: 16px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}
.multiple_date_input_wrapper {
  position: relative;
}
.multiple_date_input_wrapper .input {
  width: 100%;
  height: 43px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 8px 10px;
  position: relative;
}
.multiple_date_input_wrapper .input:hover {
  outline: 1px solid rgba(0, 0, 0, 0.87);
}
.multiple_date_input_wrapper .input .tag {
  width: auto;
  background: #eaeaea;
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 12px;
  position: relative;
  z-index: 1000;
}
.rmdp-container {
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
}
.rmdp-container input {
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0px;
  border: none;
}
.rmdp-container input:focus {
  border: none;
  box-shadow: none;
}
.rmdp-container .ep-arrow {
  display: none;
}
.add_sku_to_shelf {
  cursor: pointer;
}
.add_sku_to_shelf:hover {
  background-color: #f3f6f9;
}
.rectangle {
  position: absolute;
  border: 3px solid #ffe3a2;
}
.increaseLayer {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #00000075;
}
.analiticsImageWrapper:hover .increaseLayer {
  visibility: visible;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.imageUploader {
  width: calc(50% - 10px);
  min-height: 150px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  cursor: pointer;
}
.imageUploader img {
  max-height: 250px;
}
.imageUploader:hover {
  background-color: #f1f2f3;
}
.sticky_header {
  position: sticky;
  top: 0px;
  z-index: 100000;
  background-color: #ffffff;
  padding-top: 32px;
}
